import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb forma de pipa que presenta un capell orbicular de textura fibrosa amb una capa com de vernís, de color groguenc al marge i que forma capes concèntriques de color marró rogenc, que es va enfosquint amb l’edat. Aquest capell pot arribar a mesurar fins a 13 cm de diàmetre. Davall el capell es disposen tubs llargs, blanquinosos, que amb el temps passen a un color canyella i té porus petits i atapeïts del mateix color. El peu és lateral, cilíndric, del mateix color i aspecte vermell lacat del capell. Les espores són de color terrós, ovoides, berrugoses, de 10-14 x 6-8,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      